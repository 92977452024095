import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getBiayaEnter: state => id => state.datas.find(item => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
  },
  actions: {
    async getDataById({}, code) {
      try {
        const config = {
          url: `/setting-biaya/${code}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/setting-biaya',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/setting-biaya',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
