import service from '@/services'
import secret from "@/auth/secret";

// Module Vuex gudang-rak
export default {
  namespaced: true,
  state: {
    datas: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/auth/refresh',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async refresh({}, payload = {}) {
      try {
        const config = {
          url: '/auth/refresh',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
            secret,
            device: "web",
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
