export default [
  //AFFILIATE
  {
    path: '/transaksi-penjualan-toko',
    name: 'route-transaksi-penjualan-toko',
    component: () => import('@/views/pages/transaksi-toko/List.vue'),
    meta: {
      authRequired: true,
      module: 'Transaksi Penjualan Toko',
      pageTitle: 'Transaksi Penjualan Toko',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
