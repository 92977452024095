import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getTransaksi: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
    SET_UANG_MASUK(state, masuk) {
      state.masuks = masuk;
    },
  },
  actions: {
    async getDataById({}, params) {
      try {
        const config = {
          url: `/transaksi-offline/${params.id}`,
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        const result = response.data;
        // const result_total = response.data.recordsTotal;
        // commit("SET_DATA", result);
        // commit("SET_TOTAL", result_total);

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/transaksi-offline",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        const total_uang_masuk = response.data.total_uang_masuk;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);
        commit("SET_UANG_MASUK", total_uang_masuk);

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async tandaiLunas({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-offline/lunas",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async batalOrder({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-offline/cancel",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateStatus({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-offline/update-status",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getData2({ commit }, params = {}) {
      try {
        const config = {
          url: "/transaksi-offline",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        const result = response;
        // const result_total = response.data.recordsTotal;
        // const total_uang_masuk = response.data.total_uang_masuk;
        // commit("SET_DATA_DB1", result);
        // commit("SET_TOTAL_DB1", result_total);
        // commit("SET_UANG_MASUK_DB1", total_uang_masuk);

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
