export default [
  {
    path: "/ajuan-penarikan-komisi",
    name: "route-ajuan-penarikan-komisi",
    component: () => import("@/views/pages/ajuan/penarikan-komisi/List.vue"),
    meta: {
      module: "Ajuan Penarikan Komisi",
      authRequired: true,
      pageTitle: "Ajuan Penarikan Komisi",
      breadcrumb: [
        {
          text: "Ajuan Penarikan Komisi",
          active: true,
        },
      ],
    },
  },
  {
    path: "/ajuan-tukar-poin",
    name: "route-ajuan-tukar-poin",
    component: () => import("@/views/pages/ajuan/tukar-poin/List.vue"),
    meta: {
      module: "Ajuan Tukar Poin ",
      authRequired: true,
      pageTitle: "Ajuan Tukar Poin ",
      breadcrumb: [
        {
          text: "Ajuan Tukar Poin ",
          active: true,
        },
      ],
    },
  },
];
