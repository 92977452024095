import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
    currentTotalBayar: 0,
    currentTotalHutang: 0,
    currentKonsumen: null,
    barangs: [], // untuk nanti diinput ketika simpan (khusus pas edit) jika si sales tambah barang baru
    summarize: {
      tunai: [],
      transfer: [],
      giro: [],
      nota_putih: [],
    },
    totalBadDebt: 0,
    akunOptions: [],
    kasOptions: [],
    rutes: [],
    currentRute: null,
  },
  getters: {
    search: (state) => (id) => state.datas.find((item) => item.id == id),
    getSetoranSummarize: (state) => {
      const tunai = state.summarize.tunai.reduce((total, payment) => (total += payment.nominal), 0);
      const transfer = state.summarize.transfer.reduce((total, payment) => (total += payment.nominal), 0);
      const giro = state.summarize.giro.reduce((total, payment) => (total += payment.nominal), 0);
      const nota_putih = state.summarize.nota_putih.reduce((total, payment) => (total += payment.nominal), 0);

      return {
        tunai,
        transfer,
        giro,
        nota_putih,
      };
    },
  },
  mutations: {
    SET_CURRENT_RUTE(state, currentRute) {
      state.currentRute = currentRute;
    },
    SET_RUTE(state, datas) {
      state.rutes = datas;
    },
    SET_DATA_AKUN(state, data) {
      state.akunOptions = data;
    },
    SET_DATA_KAS(state, data) {
      state.kasOptions = data;
    },
    SET_TOTAL_BAD_DEBT(state, data) {
      state.totalBadDebt = data;
    },
    SET_SUMMARIZE_SETORAN(state, data) {
      state.summarize = data;
    },
    SET_CURRENT_KONSUMEN(state, konsumen) {
      state.currentKonsumen = konsumen;
    },
    SET_CURRENT_BARANG(state, barangs) {
      state.barangs = barangs;
    },
    SET_CURRENT_TOTAL_BAYAR(state, data) {
      state.currentTotalBayar = data;
    },
    SET_CURRENT_TOTAL_HUTANG(state, data) {
      state.currentTotalHutang = data;
    },
    SET_ONE_DATE(state, penjualan) {
      const { id } = penjualan;
      const indexPenjualan = state.datas.findIndex((item) => item.id == id);
      if (indexPenjualan >= 0) {
        state.datas[indexPenjualan] = penjualan;
      }
    },
    SET_DATA(state, data) {
      state.datas = data;
    },
  },
  actions: {
    async cancelBadDebt({}, payload) {
      const config = {
        url: "/batalkan-penjualan-tak-tertagih",
        method: "post",
        data: payload,
        headers: {
          Authorization: `${localStorage.tokenType} ${localStorage.token}`,
        },
      };

      const response = await service.sendRequest(config);

      return Promise.resolve(response.data);
    },
    async doBadDebt({}, payload) {
      const config = {
        url: "/penjualan-tak-tertagih",
        method: "post",
        data: payload,
        headers: {
          Authorization: `${localStorage.tokenType} ${localStorage.token}`,
        },
      };

      const response = await service.sendRequest(config);

      return Promise.resolve(response.data);
    },
    async printAjuan({}, bop_id) {
      try {
        const config = {
          url: `/export-setor/${bop_id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/penjualan/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/penjualan",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async transaksiToko({ commit }, params = {}) {
      try {
        const config = {
          url: "/toko/index-transaksi",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async transaksiTokoById({ commit }, id) {
      try {
        const config = {
          url: `/toko/index-transaksi/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async cancel({}, payload) {
      try {
        const config = {
          url: "/transaksi-cancel",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteBarang({}, payload) {
      try {
        const config = {
          url: "/transaksi-barang-update",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async saveBarang({}, payload) {
      try {
        const config = {
          url: "/transaksi-barang-update",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async bayar({}, payload) {
      try {
        const config = {
          url: "/input/pembayaran",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async printNota({}, penjualan_id) {
      try {
        const config = {
          url: `/cetak-penjualan/${penjualan_id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async printNotaJual({}, penjualan_id) {
      try {
        const config = {
          url: `/toko/nota-penjualan/${penjualan_id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async printEtiket({}, params) {
      try {
        const config = {
          url: `/toko/etiket/${params.id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-selesai",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async update({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-update",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async remove({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-delete",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async setor({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-setor",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async terimaSetoran({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-terima",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataSetoran({ commit }, params = {}) {
      try {
        const config = {
          url: "/transaksi-setor-list",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveSetoran({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-setor",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteSetoran({}, payload = {}) {
      try {
        const config = {
          url: "/transaksi-setor-delete",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
