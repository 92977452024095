export default [
  {
    path: "/paket-pengiriman",
    name: "route-paket-pengiriman",
    component: () => import("@/views/pages/manajemen-harga-pengiriman/paket-pengiriman/List.vue"),
    meta: {
      module: "Paket Pengiriman",
      authRequired: true,
      pageTitle: "Paket Pengiriman",
      breadcrumb: [
        {
          text: "Paket Pengiriman",
          active: true,
        },
      ],
    },
  },
  {
    path: "/aturan-tingkat-harga",
    name: "route-aturan-tingkat-harga",
    component: () => import("@/views/pages/manajemen-harga-pengiriman/aturan-tingkat-harga/List.vue"),
    meta: {
      module: "Paket Pengiriman",
      authRequired: true,
      pageTitle: "Paket Pengiriman",
      breadcrumb: [
        {
          text: "Paket Pengiriman",
          active: true,
        },
      ],
    },
  },
];
