export default [
  {
    path: '/digital',
    name: 'route-digital',
    component: () => import('@/views/pages/digital/List.vue'),
    meta: {
      module: 'Digital',
      pageTitle: 'Digital',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/digital/add',
    name: 'route-digital-add',
    component: () => import('@/views/pages/digital/Store.vue'),
    meta: {
      parent: '/digital',
      module: 'Digital',
      authRequired: true,
      pageTitle: 'Digital',
      breadcrumb: [
        {
          text: 'List',
          to: '/digital',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/digital/edit/:id',
    name: 'route-digital-edit',
    component: () => import('@/views/pages/digital/Edit.vue'),
    meta: {
      parent: '/digital',
      module: 'Digital',
      authRequired: true,
      pageTitle: 'Digital',
      breadcrumb: [
        {
          text: 'List',
          to: '/digital',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
