import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
    drivers: [],
    shippingOrders: [],
  },
  getters: {
    getJenis: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_DRIVERS(state, drivers) {
      state.drivers = drivers;
    },
    SET_SHIPPING_ORDERS(state, shippingOrders) {
      state.shippingOrders = shippingOrders;
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: `/courier-tasks/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response.data.results);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: "/courier-tasks",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);
        const result = response.data && response.data.results ? response.data.results : [];
        commit("SET_DATA", result);
        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      console.log("payload", payload);
      const id = payload.id;
      const type = payload.fungsi;
      try {
        // If id is provided, set the URL to include the id and use the PUT method
        let url = "/courier-tasks/assign";
        let method = "post";

        if (type == 2) {
          url = `/courier-tasks/assign/${id}`;
          method = "put";
          // Remove id from payload
          const { id: _, ...restPayload } = payload;
          payload = restPayload;
        }
        if (type == 1) {
          url = `/courier-tasks/${id}`;
          method = "delete";
        }

        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: url,
          method: method,
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteRule({}, id = {}) {
      try {
        // If id is provided, set the URL to include the id and use the PUT method
        let url = `/price-tier-rules/${id}`;
        let method = "delete";

        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: url,
          method: method,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createRule({}, payload = {}) {
      try {
        let url = `/price-tier-rules`;
        let method = "post";

        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: url,
          method: method,
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDrivers({ commit }, params = {}) {
      try {
        const config = {
          url: "/user",
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        };
        const response = await service.sendRequest(config);
        const drivers = response.data  ? response.data.data : [];
        commit("SET_DRIVERS", drivers);
        return Promise.resolve(drivers);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getShippingOrders({ commit },params = {}) {
      console.log("params",params);
      try {
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: "/shipping-order",
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        };
        const response = await service.sendRequest(config);
        const shippingOrders = response.data && response.data.results ? response.data.results : [];
        commit("SET_SHIPPING_ORDERS", shippingOrders);
        return Promise.resolve(shippingOrders);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
