import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getTerapis: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/terapis",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/terapis",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
