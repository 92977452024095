import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getData: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          url: `/toko-penyimpanan/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/toko-penyimpanan",
          method: "get",
          params,
          // paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data.data;
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/toko-penyimpanan-tambah",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save2({}, payload = {}) {
      try {
        const config = {
          url: "/input/toko-penyimpanan",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async publish({}, payload = {}) {
      try {
        const config = {
          url: "/toggle-publish/toko-penyimpanan",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
